import { ArrowBackIosNewOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { handleClose, handleOpen } from "../config/config";
import { t } from "i18next";
import api from "../API/apiCollection";
import { useTheme } from "@emotion/react";
import { useSelector, useDispatch } from "react-redux";

// Redux files
import { cartDetails } from "../redux/orderCartDetails";
import { DrawerDynamicAddress } from "../Components/Reusable/Profile/DrawerDynamicAddress";
import { setDeliveryAddressType } from "../redux/DeliveryAddress";
import toast from "react-hot-toast";
import { updateSelectedView } from "../redux/cart";
import DateTimeSelector from "./DateTimeSelector";

const InfoText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: theme.palette.text.secondary,
  textAlign: "center"
}));

const PrimaryButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#0277FA",
  color: "#FFFFFF",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "16px",
  fontWeight: 500,
  "&:hover": {
    backgroundColor: "#0277FA",
    opacity: 0.9,
  },
  padding: "10px 0",
  marginTop: "16px"
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: 600,
  marginBottom: "8px",
}));

const AddressDrawer = ({
  setForm,
  isSelectedSlote,
  MyFun,
  setCart,
  setBooking,
}) => {
  const dispatch = useDispatch();
  const all = useSelector((state) => state);
  const orderDetails = useSelector((state) => state.OrderCartDetails)?.orderDetails;
  const isselectedTime = orderDetails && orderDetails.slot;
  const baseCart = useSelector((state) => state.cart)?.base_cart;
  const users_address = useSelector((state) => state.UserAddress)?.address;
  const delivery_type = useSelector((state) => state.DeliveryAddress)?.deliveryType;
  const deliveryAddress = useSelector((state) => state.DeliveryAddress)?.delivery;
  const cart = useSelector((state) => state.cart);
  const selectedDate = orderDetails && orderDetails.date;
  const [note, setNote] = useState(orderDetails.orderNote);
  const theme = useTheme();


  useEffect(() => {
    if (cart.at_store === "1" && cart.at_door === "1") {
      // Nếu có cả hai loại, cho người dùng chọn
    } else if (cart.at_store === "0" && cart.at_door === "1") {
      dispatch(setDeliveryAddressType("Home"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.at_store, cart.at_door, dispatch]);

  const checkSlot = async () => {
    const partner_id = baseCart && baseCart.provider_id;
    await api
      .checkSlots({
        partner_id: partner_id,
        date: selectedDate,
        time: isselectedTime,
      })
      .then((response) => {
        if (response.error === false) {
          if (deliveryAddress !== "" && delivery_type === "Home") {
            providerAvailable(deliveryAddress.lattitude, deliveryAddress.longitude);
          } else {
            handleClose(setForm);
            handleOpen(setBooking);
          }
        } else toast.error(response.message);
      })
      .catch((error) => console.log(error));
  };

  const providerAvailable = async (lat, lng) => {
    await api
      .providerAvailable({ latitude: lat, longitude: lng, isCheckout: 1 })
      .then((result) => {
        if (result.error === false) {
          toast.success(result.message);
          handleClose(setForm);
          handleOpen(setBooking);
        } else toast.error(t("service_not_avail"));
      });
  };

  function ContinueClicked() {
    if (cart.at_store === "1" && cart.at_door === "1") {
      if (cart?.selectedView === null) {
        toast.error("Vui lòng chọn phương thức chụp!");
        return;
      }
    }

    if (selectedDate.trim() === "" || isselectedTime.trim() === "") {
      toast.error("Vui lòng chọn ngày và giờ chụp trước khi tiếp tục.");
      return;
    }

    if (delivery_type && delivery_type === "") {
      toast.error("Vui lòng chọn hình thức chụp trước khi tiếp tục.");
      return;
    }

    if (cart.at_door === "1" && delivery_type === "Home") {
      if (users_address?.length === 0) {
        toast.error("Vui lòng chọn địa chỉ trước khi tiếp tục.");
        return;
      }
    }

    if (cart.at_store === "1" && cart.at_door === "0") {
      dispatch(setDeliveryAddressType("shop"));
    }
    checkSlot();
  }

  const OpenNext = () => {
    handleClose(setForm);
    handleOpen(isSelectedSlote);
  };

  function back() {
    handleClose(setForm);
    handleOpen(setCart);
  }

  function handleSelectMethodHome() {
    dispatch(setDeliveryAddressType("Home"));
  }
  function handleSelectMethodShop() {
    dispatch(setDeliveryAddressType("shop"));
  }

  const handleChange = (event, nextView) => {
    dispatch(updateSelectedView(nextView));
  };

  const handleOrderNote = (value) => {
    setNote(value);
    dispatch(cartDetails({ orderNote: value }));
  };

  return (
    <div className="overflow-hidden">
      <Box mt={1} mb={1} display={"flex"} alignItems={"center"}>
        <Box mb={2} display="flex" alignItems="center">
          <IconButton onClick={back} size="small">
            <ArrowBackIosNewOutlined 
              sx={{ color: theme.palette.text.primary }}
              fontSize="small" 
            />
          </IconButton>
          <Typography variant="h6" fontWeight={600} ml={1}>
            {t("Chọn địa điểm và thời gian chụp")} {baseCart && " nhé " + baseCart.company_name}
          </Typography>
        </Box>
      </Box>
      <Divider />

      {/* Chọn hình thức chụp */}
      {cart.at_store === "1" && cart.at_door === "1" && (
        <Box mb={3} textAlign="center" px={2}>
          <SectionTitle>{t("Chọn hình thức chụp ảnh")}</SectionTitle>
          <ToggleButtonGroup
            orientation="vertical"
            value={cart?.selectedView}
            exclusive
            fullWidth
            onChange={handleChange}
            sx={{
              "& .MuiToggleButton-root": {
                borderRadius: "8px !important",
                textTransform: "none",
                justifyContent: "center",
                padding: "12px",
                marginBottom: "8px",
                border: "1px solid #E0E0E0",
                "&:hover": {
                  backgroundColor: "#7ab4f5"
                },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              },
              "& .Mui-selected": {
                backgroundColor: "#7ebae6 !important",
                borderColor: "#0277FA !important",
                color: theme.palette.primary.dark,
              }
            }}
          >
            <ToggleButton
              value="list"
              aria-label="home"
              onClick={handleSelectMethodHome}
            >
              <Typography variant="body1" fontWeight={500} textAlign="center">
                {t("Địa điểm của bạn")}
              </Typography>
              <InfoText>{t("Thợ chụp hình sẽ đến vị trí bạn chọn (trong bán kính cho phép)")}</InfoText>
            </ToggleButton>

            <ToggleButton
              value="module"
              aria-label="shop"
              onClick={handleSelectMethodShop}
            >
              <Typography variant="body1" fontWeight={500} textAlign="center">
                {t("Địa điểm của thợ chụp hình")}
              </Typography>
              <InfoText>{t("Đến studio, cửa hàng hoặc địa điểm do thợ chụp hình cung cấp")}</InfoText>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}

      {/* Chọn ngày giờ */}
      <Box display={"block"} px={"8px"} py={1}>
        <SectionTitle>{t("Chọn ngày và giờ chụp")}</SectionTitle>
        <DateTimeSelector onOpen={OpenNext} selectedDate={selectedDate} selectedTime={isselectedTime}/>
      </Box>

      {/* Chọn địa chỉ nếu là chụp tại địa điểm của bạn */}
      {cart.at_door === "1" && delivery_type === "Home" && (
        <>
          <Box px={"8px"} mt={2}>
            <SectionTitle>{t("Địa chỉ của bạn")}</SectionTitle>
            <DrawerDynamicAddress />
            <Box mb={2}>
              <Button
                variant="outlined"
                sx={{ textTransform: "none" }}
                fullWidth
                className="drawer-button-address"
                onClick={MyFun}
              >
                {t("Thêm địa chỉ mới")}
              </Button>
            </Box>
          </Box>
        </>
      )}

      {/* Ghi chú cho thợ chụp hình */}
      <Box px={"8px"} mt={2}>
        <Typography fontSize={16} mb={0.5} fontWeight={600}>
          {t("Ghi chú cho thợ chụp hình (tùy chọn)")}
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          placeholder={t("Nhập yêu cầu hoặc ghi chú thêm...")}
          value={note}
          onChange={(e) => handleOrderNote(e.target.value)}
        />
      </Box>

      {/* Nút tiếp tục */}
      <Box px={"8px"} pt={2} mb={2}>
        <PrimaryButton fullWidth onClick={ContinueClicked}>
          {t("Tiếp tục")}
        </PrimaryButton>
      </Box>
    </div>
  );
};

export default AddressDrawer;