import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  IconButton,
  Divider,
  useTheme,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowBackIosNewOutlined } from "@mui/icons-material";
import { t } from "i18next";
import { handleClose, handleOpen } from "../config/config";
import { useDispatch } from "react-redux";
import api from "../API/apiCollection";
import toast from "react-hot-toast";
import { setDeliveryAddress } from "../redux/DeliveryAddress";
import { setAddress } from "../redux/UserAddress";

const AddressForm = ({ setForm, addAddress }) => {
  const [note, setNote] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [addressInput, setAddressInput] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");

  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const dispatch = useDispatch();

  const [query, setQuery] = useState("");
  const [predictions, setPredictions] = useState([]);
  const [loadingPredictions, setLoadingPredictions] = useState(false);

  const [fromMap, setFromMap] = useState(false);
  const [addressError, setAddressError] = useState(false);

  const theme = useTheme();
  const MAP_API = process.env.REACT_APP_MAP_API;
  const mapRef = useRef(null);
  const geocoder = useRef(null);
  const autocompleteServiceRef = useRef(null);

  // Khởi tạo bản đồ Google Maps
  useEffect(() => {
    if (!window.google || !window.google.maps) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${MAP_API}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => initMap();
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    } else {
      initMap();
    }
    // eslint-disable-next-line
  }, []);

  const initMap = () => {
    if (!window.google || !window.google.maps) return;

    const initialPosition = { lat: 10.762622, lng: 106.660172 };
    const mapInstance = new window.google.maps.Map(mapRef.current, {
      center: initialPosition,
      zoom: 15,
      styles: [
        {
          featureType: "poi.business",
          stylers: [{ visibility: "off" }],
        },
      ],
    });
    setMap(mapInstance);

    const markerInstance = new window.google.maps.Marker({
      position: initialPosition,
      map: mapInstance,
    });
    setMarker(markerInstance);

    geocoder.current = new window.google.maps.Geocoder();

    // Sự kiện click trên bản đồ
    mapInstance.addListener("click", (mapsMouseEvent) => {
      const latlng = mapsMouseEvent.latLng;
      markerInstance.setPosition(latlng);
      mapInstance.panTo(latlng);

      geocoder.current.geocode(
        { location: { lat: latlng.lat(), lng: latlng.lng() } },
        (results, status) => {
          if (status === "OK" && results[0]) {
            const streetAddress = getStreetAddress(results[0].address_components);
            if (streetAddress) {
              setSelectedAddress(streetAddress);
              setAddressInput(streetAddress);
              setFromMap(true);
              setPredictions([]);
              setAddressError(false);
            } else {
              setSelectedAddress("");
              setAddressInput("");
              setFromMap(false);
              setAddressError(true);
              toast.error("Không thể trích xuất địa chỉ đường phố từ kết quả.");
            }
          }
        }
      );
    });
  };

  // Hàm trích xuất địa chỉ đường phố từ address_components
  const getStreetAddress = (addressComponents) => {
    const streetNumber = addressComponents.find(
      (component) => component.types.includes("street_number")
    )?.long_name;
    const route = addressComponents.find(
      (component) => component.types.includes("route")
    )?.long_name;

    if (streetNumber && route) {
      return `${streetNumber} ${route}`;
    } else if (route) {
      return route;
    } else {
      return "";
    }
  };

  // Khởi tạo Autocomplete Service
  useEffect(() => {
    if (window.google && window.google.maps && !autocompleteServiceRef.current) {
      autocompleteServiceRef.current = new window.google.maps.places.AutocompleteService();
    }
  }, []);

  // Tìm kiếm địa chỉ dựa trên query
  useEffect(() => {
    if (!autocompleteServiceRef.current) return;
    if (fromMap) {
      return;
    }

    if (query.trim() === "") {
      setPredictions([]);
      return;
    }

    setLoadingPredictions(true);

    const timeoutId = setTimeout(() => {
      autocompleteServiceRef.current.getPlacePredictions(
        {
          input: query,
          componentRestrictions: { country: "VN" },
        },
        (results, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            results
          ) {
            setPredictions(results);
          } else {
            setPredictions([]);
          }
          setLoadingPredictions(false);
        }
      );
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [query, fromMap]);

  // Khi chọn một gợi ý từ Autocomplete
  const handleSelectPrediction = (description) => {
    const prediction = predictions.find((p) => p.description === description);
    if (!prediction) return;

    setSelectedAddress(description);
    setAddressInput(description);
    setPredictions([]);
    setAddressError(false);

    geocoder.current.geocode({ address: description }, (results, status) => {
      if (status === "OK" && results[0]) {
        const location = results[0].geometry.location;
        const newPosition = { lat: location.lat(), lng: location.lng() };
        if (map && marker) {
          marker.setPosition(newPosition);
          map.setCenter(newPosition);
        }
      }
    });
  };

  // Xử lý submit form
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ngăn chặn submit nếu đang tải
    if (loading) return;

    // Kiểm tra xem địa chỉ đã được chọn từ gợi ý hay chưa
    if (!selectedAddress) {
      setAddressError(true);
      toast.error("Vui lòng chọn địa chỉ từ gợi ý.");
      return;
    }

    setLoading(true);

    try {
      const latLng = marker.getPosition();
      const latitude = latLng ? latLng.lat() : null;
      const longitude = latLng ? latLng.lng() : null;

      // Trích xuất phần đầu tiên của địa chỉ
      const city = selectedAddress.split(",")[0].trim(); // Ví dụ: "65 Dã Tượng"

      const addressType = "home";
      const isDefault = 1;
      const area = city; // Bạn có thể tùy chỉnh nếu cần

      const mobile = phone;
      const locationName = selectedAddress;

      // Gọi API AddAddress
      const response = await api.AddAddress({
        mobile: mobile,
        address: locationName,
        city_name: city,
        latitude: latitude,
        longitude: longitude,
        area: area,
        type: addressType,
        is_default: isDefault,
      });

      if (response.error === false) {
        toast.success("Thêm địa chỉ thành công");

        // Gọi API getAddress sau khi thêm thành công
        const res = await api.getAddress();
        const defaultData = res?.data?.find((item) => item.is_default === "1");
        if (defaultData) {
          dispatch(setDeliveryAddress(defaultData));
        }
        dispatch(setAddress(res?.data));

        back(); // Quay lại màn trước
      } else {
        toast.error(response.message || "Có lỗi xảy ra");
      }
    } catch (error) {
      console.log(error);
      toast.error("Có lỗi xảy ra khi thêm địa chỉ");
    } finally {
      setLoading(false);
    }
  };

  // Quay lại màn trước
  const back = () => {
    handleOpen(setForm);
    handleClose(addAddress);
  };

  // Xử lý khi người dùng thay đổi giá trị trong ô nhập liệu
  const handleInputChange = (event, value) => {
    setFromMap(false);
    setQuery(value);
    setAddressInput(value);
    setSelectedAddress(""); // Reset selectedAddress khi người dùng gõ mới
    setAddressError(false);
  };

  // Xử lý khi người dùng rời khỏi ô nhập liệu mà không chọn từ gợi ý
  const handleBlur = () => {
    if (selectedAddress !== addressInput) {
      setAddressInput("");
      setSelectedAddress("");
      setAddressError(true);
      toast.error("Vui lòng chọn địa chỉ từ gợi ý.");
    }
  };

  const options = predictions.map((p) => p.description);

  return (
    <Box sx={{ width: "100%", maxWidth: "800px", margin: "auto", p: 2 }}>
      {/* Header */}
      <Box mb={2} display="flex" alignItems="center">
        <IconButton onClick={back} size="small">
          <ArrowBackIosNewOutlined
            sx={{ color: theme.palette.text.primary }}
            fontSize="small"
          />
        </IconButton>
        <Typography variant="h6" fontWeight={600} ml={1}>
          {t("complete_address")}
        </Typography>
      </Box>
      <Divider sx={{ mb: 2 }} />

      {/* Grid Layout */}
      <Grid container spacing={2}>
        {/* Google Map */}
        <Grid item xs={12}>
          <Box
            ref={mapRef}
            sx={{
              height: "400px",
              width: "100%",
              borderRadius: "8px",
              overflow: "hidden",
              mb: 3,
              border: "1px solid #ccc",
            }}
          />
        </Grid>

        {/* Form */}
        <Grid item xs={12}>
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              {/* Address Autocomplete */}
              <Grid item xs={12}>
                <Autocomplete
                  options={options}
                  value={selectedAddress}
                  onChange={(event, value) => {
                    if (value) {
                      handleSelectPrediction(value);
                    }
                  }}
                  inputValue={addressInput}
                  onInputChange={handleInputChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Địa Chỉ")}
                      variant="outlined"
                      placeholder={t("Nhập địa chỉ")}
                      fullWidth
                      onBlur={handleBlur}
                      error={addressError}
                      helperText={
                        addressError
                          ? t("Vui lòng chọn địa chỉ từ gợi ý.")
                          : ""
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loadingPredictions ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  sx={{
                    mb: 2,
                    ".MuiAutocomplete-listbox": {
                      maxHeight: 200,
                    },
                  }}
                />
              </Grid>

              {/* Note Field */}
              <Grid item xs={12}>
                <TextField
                  label={t("Ghi chú")}
                  variant="outlined"
                  multiline
                  rows={3}
                  placeholder={t("Ghi chú (tối đa 250 ký tự)")}
                  value={note}
                  onChange={(e) => {
                    if (e.target.value.length <= 250) {
                      setNote(e.target.value);
                    }
                  }}
                  fullWidth
                  sx={{ mb: 2 }}
                />
              </Grid>

              {/* Phone Field */}
              <Grid item xs={12}>
                <TextField
                  label={t("Số điện thoại")}
                  variant="outlined"
                  placeholder={t("Số điện thoại")}
                  value={phone}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, "");
                    setPhone(numericValue);
                  }}
                  inputProps={{ pattern: "[0-9]*", maxLength: 16 }}
                  fullWidth
                  sx={{ mb: 3 }}
                />
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={loading}
                  sx={{
                    textTransform: "none",
                    fontWeight: "bold",
                    py: 1.5,
                  }}
                >
                  {loading && (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      style={{ marginRight: 8 }}
                    />
                  )}
                  {t("add_address")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddressForm;