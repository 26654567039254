// Import các thư viện cần thiết
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
  Grid,
} from "@mui/material";
import {
  ArrowBackIosNewOutlined,
  ConfirmationNumber,
  Place,
  AccessTime,
  Delete,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// Import MoMoPayment
import MoMoPayment from "../PaymentGateways/MoMoPayment"; // Thêm MoMoPayment
// Import Redux actions
import {
  selectedPromoCodeData,
  setPromoCode,
  setSelectedPromocode,
} from "../redux/Promocode";
import { cartDetails, orderCartDetailsReset } from "../redux/orderCartDetails";
import { resetState } from "../redux/cart";
import { paymentMode, setPaymentMode } from "../redux/Settings";
import api from "../API/apiCollection";
import { formatMoney } from "../util/Helper"; // Đảm bảo rằng bạn đã tạo hàm này

// Styled components
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const BookingInfoDrawerNew = ({ setForm, setPromo, setBooking }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State và biến
  const [rows, setRows] = useState([]);
  const [amount, setAmount] = useState(0);
  const [visitingCharges, setVisitingCharges] = useState(0);
  const [addressInfo, setAddressInfo] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [activePaymentMethod, setActivePaymentMethod] = useState(null);

  // Sử dụng Redux selectors
  const settings = useSelector((state) => state.Settings)?.settings;
  const currency_symbol = settings?.app_settings?.currency || "₫"; // Thêm fallback nếu không có
  const delivery_type = useSelector((state) => state.DeliveryAddress)?.deliveryType;
  const orderDetails = useSelector((state) => state.OrderCartDetails)?.orderDetails;
  const deliveryAddress = useSelector((state) => state.DeliveryAddress)?.delivery;
  const selectedPromo = useSelector(selectedPromoCodeData);
  const paymentModeData = useSelector(paymentMode);
  const AllCartData = useSelector((state) => state.cart);
  const promocode = useSelector((state) => state.Promocode);
  const coupanCodes = promocode.promocode;
  const selectedPromoCode = promocode.selectedPromoCode;

  const decimal_point = 2; // Điều chỉnh nếu cần

  useEffect(() => {
    // Thiết lập amount và rows dựa trên dữ liệu giỏ hàng
    let cartData = AllCartData?.isFrom === "reorder" ? AllCartData?.reOrder : AllCartData?.base_cart;
    if (cartData) {
      let totalAmount = Number(cartData.sub_total);
      if (delivery_type === "Home") {
        totalAmount += Number(cartData.visiting_charges);
        setVisitingCharges(Number(cartData.visiting_charges));
      }
      setAmount(totalAmount);
      setRows(cartData.data);
    }
  }, [AllCartData, delivery_type]);

  useEffect(() => {
    if (deliveryAddress) {
      setAddressInfo(deliveryAddress);
    }
  }, [deliveryAddress]);

  // Xử lý nút quay lại
  const back = () => {
    setBooking(false);
    setForm(true);
  };

  // Mở drawer mã giảm giá
  const openPromo = () => {
    setBooking(false);
    setPromo(true);
  };

  // Xóa mã giảm giá
  const removePromo = () => {
    dispatch(setPromoCode([]));
    if (selectedPromo) {
      dispatch(setSelectedPromocode(null));
      toast.success(t("Đã xóa mã giảm giá"));
    } else {
      toast.error(t("Không có mã giảm giá nào được chọn"));
    }
  };

  // Thiết lập phương thức thanh toán (Chỉ giữ lại MoMo và tự động chọn)
  useEffect(() => {
    const methods = [
      { title: "Thanh toán qua QR đa năng", paymentType: "momo", enabled: true }, // Chỉ giữ lại MoMo với tiêu đề mới
    ];
    setPaymentMethods(methods);
    setActivePaymentMethod("momo"); // Tự động chọn MoMo
  }, []);

  // Đặt hàng
  const placeOrder = async () => {
    let address = "";
    if (delivery_type === "Home") {
      address = deliveryAddress;
    }

    try {
      const result = await api.placeOrder({
        method: paymentModeData,
        date: orderDetails?.date,
        time: orderDetails?.slot,
        addressId: delivery_type === "Home" ? address?.id : "",
        order_note: orderDetails?.orderNote,
        promo_code_id: selectedPromoCode?.id,
      });

      if (paymentModeData === "cod") {
        toast.success(t("Đặt dịch vụ thành công"));
      } else {
        toast.success(result.message);
      }

      if (!result.error) {
        setTimeout(async () => {
          await api.add_transactions({
            orderID: result.data.order_id,
            status: "success",
          });

          dispatch(orderCartDetailsReset());
          dispatch(resetState());
          navigate("/profile/booking/services/" + result.data.order_id);
          dispatch(cartDetails({ orderNote: "" }));
        }, 2000);
      }
    } catch (error) {
      console.log("error", error);
      toast.error(t("Đặt hàng thất bại, vui lòng thử lại."));
    }
  };

  // Render component thanh toán dựa trên phương thức được chọn (Chỉ xử lý MoMo)
  const renderPaymentComponent = () => {
    const paymentAmount = coupanCodes.length > 0 ? amount - coupanCodes[0]?.final_discount : amount;
    if (activePaymentMethod === "momo") {
      return <MoMoPayment amount={paymentAmount} />;
    }
    return null;
  };

  return (
    <Box sx={{ width: "100%" }}>
      {/* Header với tiêu đề và subtitle */}
      <Box mb={2} display="flex" alignItems="center">
        <IconButton onClick={back} size="small">
          <ArrowBackIosNewOutlined 
            sx={{ color: theme.palette.text.primary }}
            fontSize="small" 
          />
        </IconButton>
        <Typography variant="h6" fontWeight={600} ml={1}>
          {t("Thanh toán dịch vụ trả trước 30%")}
        </Typography>
      </Box>
      {/* <Typography variant="subtitle2" color="text.secondary" textAlign="center">
        {t("An toàn, bảo mật")}
      </Typography> */}
      <Divider sx={{ mb: 2 }} />

      <Box sx={{ px: 2 }}>
        {/* Thông tin đặt dịch vụ */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center", bgcolor: "background.paper", p: 2, borderRadius: 1 }}>
              <AccessTime sx={{ mr: 2 }} />
              <Box>
                <Typography variant="body2">{t("Ngày đặt")}</Typography>
                <Typography variant="subtitle1" fontWeight="bold">
                  {dayjs(orderDetails?.date).format("DD/MM/YYYY")}, {orderDetails?.slot}
                </Typography>
              </Box>
            </Box>
          </Grid>

          {delivery_type === "Home" && (
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center", bgcolor: "background.paper", p: 2, borderRadius: 1 }}>
                <Place sx={{ mr: 2 }} />
                <Box>
                  <Typography variant="body2">{t("Địa chỉ của bạn")}</Typography>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {addressInfo?.address}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <Button variant="outlined" fullWidth onClick={openPromo} sx={{ textTransform: "none" }}>
              {t("Áp dụng mã giảm giá")}
            </Button>
          </Grid>

          {coupanCodes.length > 0 && (
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center", bgcolor: "secondary.light", p: 2, borderRadius: 1 }}>
                <ConfirmationNumber sx={{ mr: 2 }} />
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="subtitle1">
                    {selectedPromoCode?.promo_code}
                  </Typography>
                  <Typography variant="body2">
                    {selectedPromoCode?.discount}
                    {selectedPromoCode?.discount_type === "percentage" ? "%" : currency_symbol}
                  </Typography>
                </Box>
                <IconButton onClick={removePromo}>
                  <Delete />
                </IconButton>
              </Box>
            </Grid>
          )}
        </Grid>

        {/* Bảng chi tiết giỏ hàng */}
        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.id}>
                  <TableCell>{row.servic_details.title}</TableCell>
                  <TableCell align="right">{row.qty}</TableCell>
                  <TableCell align="right">
                    {formatMoney(row.servic_details.price_with_tax, currency_symbol)}
                  </TableCell>
                </StyledTableRow>
              ))}
              <StyledTableRow>
                <TableCell>{t("Tạm tính")}</TableCell>
                <TableCell colSpan={2} align="right">
                  {formatMoney(
                    AllCartData?.isFrom === "reorder" ? AllCartData?.reOrder?.sub_total : AllCartData?.base_cart?.sub_total,
                    currency_symbol
                  )}
                </TableCell>
              </StyledTableRow>
              {delivery_type === "Home" && (
                <StyledTableRow>
                  <TableCell>{t("Phí dịch vụ")}</TableCell>
                  <TableCell colSpan={2} align="right">
                    +{formatMoney(visitingCharges, currency_symbol)}
                  </TableCell>
                </StyledTableRow>
              )}
              {coupanCodes.length > 0 && (
                <StyledTableRow>
                  <TableCell>{t("Giảm giá")}</TableCell>
                  <TableCell colSpan={2} align="right">
                    -{formatMoney(coupanCodes[0]?.final_discount, currency_symbol)}
                  </TableCell>
                </StyledTableRow>
              )}
              <StyledTableRow>
                <TableCell>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t("Tổng cộng")}
                  </Typography>
                </TableCell>
                <TableCell colSpan={2} align="right">
                  <Typography variant="subtitle1" fontWeight="bold" color="primary">
                    {formatMoney(
                      amount - (coupanCodes.length > 0 ? coupanCodes[0]?.final_discount : 0),
                      currency_symbol
                    )}
                  </Typography>
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* Phương thức thanh toán (Chỉ MoMo) */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            {t("Thanh toán qua QR đa năng")}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 2 }}>
            {t("Tất cả các ngân hàng và ví điện tử Việt Nam")}
          </Typography>
          {/* Hiển thị component thanh toán MoMo */}
          {renderPaymentComponent()}
        </Box>
      </Box>
    </Box>
  );
};

export default BookingInfoDrawerNew;