// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  at_door: 0,
  at_store: 0,
  base_cart: [],
  selectedView: null,
  provider_info: {},
  isFrom: null,
  reOrder: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    updateDoor: (state, action) => {
      state.at_door = action.payload;
    },
    updateStore: (state, action) => {
      state.at_store = action.payload;
    },
    updateBaseCart: (state, action) => {
      state.base_cart = action.payload;
    },
    updateSelectedView: (state, action) => {
      state.selectedView = action.payload;
    },
    updateProviderInfo: (state, action) => {
      state.provider_info = action.payload;
    },
    setFrom: (state, action) => {
      state.isFrom = action.payload;
    },
    setReorder: (state, action) => {
      state.reOrder = action.payload;
    },
    resetState: (state) => {
      return initialState;
    },
  },
});

export const {
  updateDoor,
  updateStore,
  updateBaseCart,
  resetState,
  updateSelectedView,
  updateProviderInfo,
  setFrom,
  setReorder,
} = cartSlice.actions;

export default cartSlice.reducer;
