import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const DateTimeSelector = ({ selectedDate, selectedTime, onOpen }) => {
  const theme = useTheme();

  const displayDateTime = () => {
    if (selectedDate && selectedTime) {
      const date = new Date(selectedDate);
      const formattedDate = date.toLocaleDateString("vi-VN", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      return `Vào ngày ${formattedDate} lúc ${selectedTime}`;
    }
    return "Chưa chọn ngày giờ";
  };

  return (
    <Box
      onClick={onOpen}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
        px: 3,
        py: 2,
        borderRadius: "12px",
        cursor: "pointer",
        textAlign: "center",
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.paper,
        transition: "all 0.3s ease",
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
          borderColor: theme.palette.text.primary,
        },
      }}
    >
      <AccessTimeIcon
        sx={{
          fontSize: 36,
          color: theme.palette.text.secondary,
        }}
      />
      <Typography
        variant="body2"
        sx={{
          fontSize: 14,
          fontWeight: 500,
          color: theme.palette.text.primary,
        }}
      >
        {displayDateTime()}
      </Typography>
      <Typography
        variant="caption"
        sx={{
          fontSize: 12,
          fontWeight: 400,
          color: theme.palette.text.secondary,
        }}
      >
        Bấm để chọn ngày giờ
      </Typography>
    </Box>
  );
};

export default DateTimeSelector;