import {
  ArrowBackIosNewOutlined,
  BorderColorTwoTone,
} from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { t } from "i18next";
import { useTheme } from "@emotion/react";
import { MAP_API, handleClose } from "../../../config/config";
import api from "../../../API/apiCollection";
import { useDispatch, useSelector } from "react-redux";
import { setAddress } from "../../../redux/UserAddress";
import { setDeliveryAddress } from "../../../redux/DeliveryAddress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import UpdateGoogleMapBox from "../../GoogleMap/UpdateGoogleAddress";
import { LocationOnOutlined, PhoneOutlined } from "@mui/icons-material";
import toast from "react-hot-toast";

export const DrawerDynamicAddress = () => {
  const [addressSelected, setAddressSelected] = useState();

  const [deleteIndex, setDeleteIndex] = useState(null);

  const dispatch = useDispatch();

  const [openDrawer, setOpenDrawer] = useState(false);

  const [index, setIndex] = useState("");

  const userAddress = useSelector((state) => state.UserAddress).address;

  const filterAddress =
    userAddress && userAddress?.filter((item) => item.id === index);

  const [checkedRadio, setCheckRadio] = useState("");

  const [loading, setLoading] = useState(false);

  const [locationName, setLocationName] = useState();

  const [latitude, setLatitudex] = useState();

  const [longitude, setLongitudex] = useState();

  const [city, setCity] = useState();

  const [appartment, setAppartment] = useState();

  const [addressType, setAddressType] = useState();

  const [isDefault, setIsDefault] = useState(1);

  // Check if filterAddress has elements before accessing properties
  const editMobileInitialValue =
    filterAddress && filterAddress?.length > 0 ? filterAddress[0]?.mobile : "";

  const [editMobile, setEditMobile] = useState(editMobileInitialValue);

  const AddAddress = async () => {
    setLoading(true);
    await api
      .AddAddress({
        id: index,
        mobile: editMobile,
        address: locationName,
        city_name: city,
        latitude: latitude,
        longitude: longitude,
        area: appartment,
        type: addressType,
        country_code: 91,
        is_default: isDefault ? 1 : 0,
      })
      .then(async (result) => {
        setLoading(false);
        if (result.message === "address not exist") {
          toast.error("Address not exist");
        }
        if (result.error === true) {
          for (var key of Object.keys(result.message)) {
            if (key === "address") toast.error("Address is required");
            if (key === "city_name") toast.error("City Name is required");
            if (key === "area") toast.error("Area/Appartment Name is required");
            if (key === "mobile") toast.error("Mobile Number is required");
            if (key === "type") toast.error("The type field is required");
          }
        } else {
          toast.success(result.message);
          await api.getAddress().then((res) => {
            setLoading(false);
            const defaultData = res?.data?.filter(
              (item) => item.is_default === "1"
            );
            dispatch(setDeliveryAddress(defaultData[0]));
            dispatch(setAddress(res.data));
            back();
          });
          handleClose(setOpenDrawer);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const back = () => {
    handleClose(setOpenDrawer);
  };

  const handleMobileChang = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    // Limit the input to a maximum of 16 characters
    if (numericValue?.length <= 16 || numericValue === "") {
      setEditMobile(numericValue);
    }
  };

  const handleDelete = (index) => {
    setDeleteIndex(index);
  };

  const handleDeleteClose = () => {
    setDeleteIndex(null);
  };

  const handleSelect = (index, address) => {

    console.log("HELLO 2", address)
    setAddressSelected(address);
    setCheckRadio(address.id);
    dispatch(setDeliveryAddress(address));
  };

  const editAddressDrawer = (address) => {
    setOpenDrawer(!openDrawer);
    setIndex(address.id);
    setLocationName(address.address);
    setAppartment(address.area);
    setCity(address.city_name);
    setLongitudex(address.longitude);
    setLatitudex(address.lattitude);
    setEditMobile(address.mobile);
    setAddressType(address.type.toLowerCase());
    dispatch(setDeliveryAddress(address));

    if (address.is_default === "1") {
      setIsDefault(1);
    } else {
      setIsDefault(0);
    }
  };

  useEffect(() => {
    if (addressSelected !== undefined) {
      dispatch(setDeliveryAddress(addressSelected));
    }
  }, [addressSelected]);

  // to delete particular address
  // const handleDeleteAddress = async (address_id) => {
  //   await api
  //     .DeleteAddress({ address_id: address_id })
  //     .then(async (result) => {
  //       toast.success(result.message);

  //       await api
  //         .getAddress()
  //         .then((response) => {
  //           dispatch(setAddress(response.data));
  //         })
  //         .catch((error) => console.log("error", error));
  //     })
  //     .catch((error) => console.log("error", error));
  //   handleDeleteClose();
  // };

  const handleDeleteAddress = async (address_id) => {
    await api
      .DeleteAddress({ address_id: address_id })
      .then(async (result) => {
        toast.success(result.message);
  
        const updatedAddresses = await api.getAddress();
        dispatch(setAddress(updatedAddresses.data));
        
        // Update local state
        setAddressSelected(undefined);
        const newDefaultAddress = updatedAddresses.data.find(item => item.is_default === "1");
        if (newDefaultAddress) {
          setCheckRadio(newDefaultAddress.id);
          dispatch(setDeliveryAddress(newDefaultAddress));
        } else if (updatedAddresses.data.length > 0) {
          setCheckRadio(updatedAddresses.data[0].id);
          dispatch(setDeliveryAddress(updatedAddresses.data[0]));
        } else {
          setCheckRadio("");
          dispatch(setDeliveryAddress(null));
        }
      })
      .catch((error) => console.log("error", error));
    handleDeleteClose();
  };

  const theme = useTheme();

  const handleLocationSelect = (address) => {
    setCity(address.city);
  };

  // set for first time default check
  // useEffect(() => {
  //   const defaultAddress =
  //     userAddress && userAddress?.filter((item) => item.is_default === "1");
  //   setCheckRadio(defaultAddress[0]?.id);
  //   dispatch(setDeliveryAddress(defaultAddress[0]));
  // }, []);

  useEffect(() => {
    const defaultAddress = userAddress?.find(item => item.is_default === "1");
    if (defaultAddress) {
      setCheckRadio(defaultAddress.id);
      dispatch(setDeliveryAddress(defaultAddress));
    } else if (userAddress && userAddress.length > 0) {
      // If no default address, select the first one
      setCheckRadio(userAddress[0].id);
      dispatch(setDeliveryAddress(userAddress[0]));
    } else {
      // If no addresses left, clear the selection
      setCheckRadio("");
      dispatch(setDeliveryAddress(null));
    }
  }, [userAddress, dispatch]);

  const commonProps = { editLatitude: latitude, editLongitude: longitude };
  return (
    <Box
      sx={{
        gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
      }}
      width={"100%"}
      alignContent={"center"}
    >
      <Grid container width={"100%"}>
        {userAddress?.length > 0 ? (
          userAddress?.map((address, index) => {
            // console.log("LOGGG", address)
            return (
        
              <Grid item xs key={index} width={"100%"} sm={12} md={6}>
                <Box
                  key={index}
                  display={"block"}
                  sx={{
                    mx: 1,
                    my: 2,
                    maxWidth: "100%",
                    border: "1px solid gray",
                    borderRadius: "10px",
                    p: 1,
                  }}
                >
                  <Box width={"100%"} textAlign={"center"} alignItems={"center"}>
                    <Grid container width={"100%"}>
                      <Grid
                        item
                        xs
                        width={"100%"}
                        display={"flex"}
                        sm={12}
                        md={6}
                        alignItems={"center"}
                        flexWrap={"wrap"}
                      >
                        <Typography
                          gutterBottom
                          variant="p"
                          component="div"
                          display={"flex"}
                          alignItems={"flex-start"} // Căn văn bản về phía trên (trái)
                          sx={{
                            fontSize: "1rem", // Kích thước chữ vừa phải
                            color: checkedRadio === address.id ? "primary.main" : "text.secondary", // Thay đổi màu theo trạng thái
                            fontWeight: checkedRadio === address.id ? "bold" : "normal", // Chữ đậm khi được chọn
                            lineHeight: "1.5", // Tăng khoảng cách giữa các dòng
                            display: "-webkit-box", // Sử dụng để hỗ trợ lineClamp
                            WebkitBoxOrient: "vertical", // Đặt hướng khung
                            overflow: "hidden", // Ẩn phần dư
                            textOverflow: "ellipsis", // Thêm dấu "..." nếu vượt quá
                            WebkitLineClamp: 2, // Giới hạn tối đa 2 dòng
                          }}
                        >
                          <Radio
                            checked={checkedRadio == address.id}
                            onChange={() => {
                              handleSelect(index, address);
                            }}
                            value={address.id}
                            name="radio-buttons"
                            inputProps={{ "aria-label": "A" }}
                            sx={{
                              marginRight: 1.5, // Tăng khoảng cách giữa Radio và text
                              padding: 0, // Loại bỏ padding mặc định
                            }}
                          />
                          <span>{address.city_name}</span>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        width={"100%"}
                        sm={12}
                        md={6}
                        display={"flex"}
                        alignItems={"center"}
                        sx={{ mb: { sm: 2, md: 0 }, float: "right" }}
                      >
                        <Box
                          width={"100%"}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"end"}
                        >
                          {/* <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={(e) => {
                              editAddressDrawer(address);
                            }}
                            sx={{
                              backgroundColor: "green",
                              color: "white",
                              mr: 1,
                              borderRadius: 2,
                              "&:hover": {
                                backgroundColor: "green",
                              },
                            }}
                          >
                            <BorderColorTwoTone fontSize="small" />
                          </IconButton> */}
  
                          <IconButton
                            aria-label="delete"
                            size="small"
                            sx={{
                              backgroundColor: "red",
                              color: "white",
                              mr: 1,
                              borderRadius: 2,
                              "&:hover": {
                                backgroundColor: "red",
                              },
                            }}
                            onClick={() => handleDelete(index)}
                          >
                            <FontAwesomeIcon icon={faTrashCan} />
                          </IconButton>
                        </Box>
  
                        <Backdrop open={deleteIndex === index}>
                          <Box
                            sx={{ background: theme.palette.background.box }}
                            p={4}
                          >
                            <Typography>{t("delete_address")}</Typography>
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => handleDeleteAddress(address.id)}
                            >
                              {t("delete")}
                            </Button>
                            <Button onClick={handleDeleteClose}>
                              {t("close")}
                            </Button>
                          </Box>
                        </Backdrop>
                      </Grid>
                    </Grid>
                  </Box>
                  {/* Địa chỉ */}
                  <Box display="flex" alignItems="center" mb={1}>
                    <LocationOnOutlined color="action" sx={{ mr: 1 }} />
                    <Typography color="text.secondary" variant="body2">
                      {address.address}
                    </Typography>
                  </Box>

                  {/* Số điện thoại */}
                  <Box display="flex" alignItems="center">
                    <PhoneOutlined color="action" sx={{ mr: 1 }} />
                    <Typography color="text.secondary" variant="body2">
                      {address.mobile}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            )
          })
        ) : (
          <Box width={"100%"}>
            <Typography py={4} width={"100%"} textAlign={"center"}>
              {t("no_address")}
            </Typography>
          </Box>
        )}
      </Grid>
      <Drawer
        open={openDrawer}
        anchor="right"
        sx={{
          display: { xs: "block", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: { md: 580, xs: "100%" },
          },
        }}
      >
        <Box>
          <Box mt={1} mb={1} display={"flex"} alignItems={"center"}>
            <IconButton onClick={back}>
              <ArrowBackIosNewOutlined />
            </IconButton>
            <h3>{t("complete_address")}</h3>
          </Box>
          <Divider />
          <UpdateGoogleMapBox
            apiKey={MAP_API}
            onSelectLocation={handleLocationSelect}
            {...commonProps}
          />
          <Box p={2}>
            <Box>
              <Typography>{t("house_flat")}</Typography>
              <TextField
                placeholder="Enter House no"
                fullWidth
                className="address-form"
                value={locationName}
                sx={{
                  mb: 2,
                  backgroundColor: theme.palette.background.input,
                }}
                onChange={(e) => setLocationName(e.target.value)}
              />
              <Typography>{t("appartment_road")}</Typography>
              <TextField
                id="apartment"
                placeholder="Enter Road Name"
                fullWidth
                defaultValue={appartment}
                sx={{
                  mb: 2,
                  backgroundColor: theme.palette.background.input,
                }}
                onChange={(e) => setAppartment(e.target.value)}
                className="address-form"
              />
              <Typography>{t("city")}</Typography>
              <TextField
                id="city"
                placeholder="Enter City Name"
                value={city}
                sx={{
                  mb: 2,
                  backgroundColor: theme.palette.background.input,
                }}
                onChange={(e) => setCity(e.target.value)}
                fullWidth
                className="address-form"
              />
              <Typography>{t("mobile")}</Typography>
              <TextField
                id="mobile"
                placeholder="Enter Mobile Number"
                fullWidth
                className="address-form"
                value={editMobile}
                required
                sx={{
                  mb: 2,
                  backgroundColor: theme.palette.background.input,
                }}
                onChange={handleMobileChang}
                inputProps={{
                  pattern: "[0-9]*", // Allow only numeric input
                  inputMode: "numeric", // Display numeric keyboard on mobile devices
                }}
              />
            </Box>

            <Button
              variant="contained"
              fullWidth
              className="address-form"
              onClick={(e) => AddAddress(e)}
              disabled={loading === true ? true : false}
            >
              {loading === true ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                ""
              )}
              {t("continue")}
            </Button>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};
